import { Check } from '@material-ui/icons';
import {
  Checkbox,
  Step,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Car from "../../../icons/Drivers";
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { filtersChips, getSteps, initialNotification, initialValues, steps as stepsData } from '../data';

import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';

import ButtonsSteps from '../components/ButtonsSteps';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import NotificationState from '../../NotificationState';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import ScheduleApi from '../../../api/ScheduleApi';
import config from '../../../config/config';
import customCheckboxRadioSwitch from '../../../assets/components/customCheckboxRadioSwitch';
import moment from 'moment';
import useStateRef from 'react-usestateref';
import { useStylesReassignAppStepper } from '../useStylesReassignAppStepper';
import { withTranslation } from 'react-i18next';
import ServicesSummary from '../components/ServicesSummary';
import DialogTraslado from '../../TransferAppointments/Traslado/DialogTraslado.js';
import { browserHistory } from "react-router";
import CustomSweetAlert from '../../CustomSweetAlert';

const dateFormat = config.getDateFormat();
const dateToServer = config.getDateToServer();

const useStylesChecks = makeStyles(customCheckboxRadioSwitch);

const ReassignAppointmentsStepper = (props) => {
  const { t } = props;
  const classes = useStylesReassignAppStepper();
  const classesChecks = useStylesChecks();

  const [activeStep, setActiveStep] = useState(0);
  const [itemsSteps, setItemsSteps] = useState(getSteps(props.location?.state?.appointment?.transferWay || props.location?.state?.transfer?.type));
  const [stepsValues, setStepsValues, refStepsValues] = useStateRef(initialValues);
  const [skipped, setSkipped] = useState(new Set());
  const [transfer,setTransfer ]= useState([]);
  const [notification, setNotification] = useState(initialNotification);
  const [isValidStep, setIsValidStep] = useState(false);
  const [isNextStep, setIsNextStep] = useState(false);
  const [checkAllSchedules, setCheckAllSchedules] = useState(false);
  const [customerDetail, setCustomerDetail] = useState({})
  const [optionsMap, setOptionsMap] = useState({})
  const [recurrence, setRecurrence] = useState({})
  const [hospitals, setHospitals] = useState([])
  const [prestadores, setPrestadores] = useState([])
  const [showConfirmationAddTransfer, setShowConfirmationAddTransfer] = useState(false)

  console.log("LAS PROPS",props)

  const showSnackbarNotification = (message, color = 'warning') => { // steps
    setNotification((prev) => ({
      ...prev,
      color,
      message,
      open: true,
    }));
    
    setTimeout(() => {
      setNotification((prev) => ({ ...prev, open: false }));
      setTimeout(() => {
        setNotification(initialNotification);
      }, 50);
    }, 6000);
  }
 

  const goBack = () => {
    //browserHistory.goBack();
    console.log( props.location)
    browserHistory.push({
      pathname: props?.location?.state?.origin ? `transferappointments/${props?.location?.state?.origin }/edit/agenda` : "/nueva-solicitud-traslado",
      state: { transferObject: transfer }
    });
  }

  const getPatient =()=> {
    PersonApiInvoker.getCustomer((props.location.state.appointment.customer || props.location.state.appointment.customerId), data => {
        let updatedAddress  = data?.address?.map(addr => {
          if (addr.department === "") {
            addr.department = "-";
          }
          return addr;
        });
        let newData = { ...data, address: updatedAddress };
        setCustomerDetail(newData)
        setOptionsMap({
          center:{
            latitude:data?.address?.[0].latitude,
            longitude:data?.address?.[0].longitude,
          }
        })
        
    }, _ => {
        //this.setState({ loading: false })
        return 
    })
  }

  const handleSkipStep = () => setActiveStep((prev) => prev + 1); // buttons
  
  const handleBack = () => setActiveStep((prev) => prev - 1); // buttons
  
  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => { // buttons
    setIsNextStep(true);
    // error
    /*if (!isValidStep) {
      showSnackbarNotification(t('common.messageWarning.fieldsComplete'));
      return;
    }*/

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (isValidStep) setItemsSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      let completed = true;
      updatedSteps[activeStep] = {...updatedSteps[activeStep], completed };
      return updatedSteps;
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    window.scrollTo(0, 0);
  };

  const handleChange = (name, value, isValid = true) => { // steps
    if (name == "traslado") {
      setCustomerDetail(prev => ({
        ...prev,
        [name]: value,
      }));
    }else{
      setStepsValues((prev) => ({
        ...prev,
        [name]: value,
      }));
      setIsValidStep(isValid);

    }
  };

  const handleIsValidStep = (value) => setIsValidStep(value); // steps

  const isConfirmStep = activeStep === itemsSteps.length - 1; // steps

  const softFilter = (filters) => { // step 0
    setStepsValues((prev) => ({
      ...prev,
      filters: filtersChips,
    }));

    if (filters.chips.length) {
      filters.chips.forEach(e => {
        setStepsValues((prev) => ({
          ...prev,
          filters: {
            ...prev.filters,
            [e.code]: e.value,
          }
        }));
      });
    }
  }

  const onSelectAllSchedules = (value) => { // step 0
    setCheckAllSchedules(value.target.checked);
    let schedSelected = [];
    if (value.target.checked) {
      schedSelected = stepsValues.schedules.map(schedule => schedule.scheduleId);
    }
    formatTableData(stepsValues.originalschedule, schedSelected);  
  }

  const onSelectSchedule = (id) => { // step 0
    const isProfessionals = refStepsValues.current.professionalsToReassign.length;
    isProfessionals && handleChange('professionalsToReassign', []);
    setCheckAllSchedules(false);
    const selected = refStepsValues.current.schedulesSelected;
    if (selected.includes(id)) {
      const index = selected.indexOf(id);
      if (index !== -1) selected.splice(index, 1);
    } else {
      selected.push(id);
    }
    formatTableData(refStepsValues.current.originalschedule, selected);
  }

  const formatTableData = (data, selectedSched) => { // step 0
    const result = data
      .filter(f => f.scheduleStatus === 'PENDING_APPROVAL' || f.scheduleStatus === 'REJECTED' || f.scheduleStatus === 'APPROVED')
      .map(d => {
        const isChecked = selectedSched.includes(d.scheduleId);
        const action = (
          <Checkbox
            id={`checkbox-${d.scheduleId}`}
            tabIndex={-1}
            checked={isChecked}
            indeterminate={false}
            onClick={() => onSelectSchedule(d.scheduleId)}
            checkedIcon={<Check className={classesChecks.checkedIcon} />}
            icon={<Check className={classesChecks.uncheckedIcon} />}
            classes={{
              checked: classesChecks.checked,
              root: classesChecks.checkRoot,
            }}
          />
        );
        return {
          scheduleId: d.scheduleId,
          appointmentId: d.appointmentId,
          scheduleDateTime: d.scheduleDateTime,
          scheduleDate: (d.scheduleDateTime ? moment(d.scheduleDateTime).format(dateFormat) : ''),
          scheduleTime: (d.scheduleDateTime ? moment(d.scheduleDateTime).format('hh:mm a') : ''),
          scheduleStatus: t(d.scheduleStatus),
          practiceTypeName: d.practiceTypeName,
          professional: `${d.employeeLastName} ${d.employeeFirstName}`,
          customer: `${d.customerLastName} ${d.customerFirstName}`,
          province: d.province,
          location: d.location,
          geographicZone: d.geographicZone,
          selector: action,
          checkedSchedule: isChecked,
        };
    });

    setStepsValues((prevState) => ({
      ...prevState,
      schedulesSelected: selectedSched,
      schedules: result,
      originalquery: result,
      originalschedule: data,
      loadingTable: false,
    }), () => softFilter(stepsValues));
  }

  const getSchedulesReasign = (steps, startDate, endDate) => {
    const { appointmentId, customerId, dayOfWeek, employeeId, practiceTypeId, startTime, evenDay } = steps.filters;
    let evenDayValue;
    if (evenDay === 1) {
      evenDayValue = true;
    }
    if (evenDay === 2) {
      evenDayValue = false;
    }

    const employeeIdQuery = employeeId ? `&employeeId=${employeeId}` : '';
    const appointmentIdQuery = appointmentId ? `&appointmentId=${appointmentId}` : '';
    const practiceTypeIdQuery = practiceTypeId ? `&practiceTypeId=${practiceTypeId}` : '';
    const customerIdQuery = customerId ? `&customerId=${customerId}` : '';
    const startTimeQuery = startTime ? `&start-time=${startTime}` : '';
    const evenDayQuery = evenDay !== null ? `&even-day=${evenDayValue}` : '';
    const dayOfWeekQuery = dayOfWeek ? `&day-of-week=${dayOfWeek}` : '';
    const params = {
      startDate,
      endDate,
      employeeId: employeeIdQuery,
      appointmentId: appointmentIdQuery,
      practiceTypeId: practiceTypeIdQuery,
      customerId: customerIdQuery,
      startTime: startTimeQuery,
      evenDay: evenDayQuery,
      dayOfWeek: dayOfWeekQuery,
    }

    ScheduleApi.getSchedulesReasign(params, (data) => {
      formatTableData(data, []);
      setStepsValues((prev) => ({ ...prev, loadingSchedule: false }));
      }, (error) => {
        const { message, statusText } = error;
        const notificationMessage = typeof message === "string" ? message : statusText;
        showSnackbarNotification(notificationMessage);
        setStepsValues((prev) => ({ ...prev, loadingSchedule: false }));
      }
    );
  }

  const setFilters = (filters) => { // step 0
    setCheckAllSchedules(false);
    const startReassign = moment(filters.startDate).format(dateToServer);
    const endReassign = moment(filters.endDate).format(dateToServer);
    if (filters.startDate.length > 0 && filters.endDate.length > 0) {
      setStepsValues((prev) =>({
        ...prev,
        startDate: startReassign,
        endDate: endReassign,
        dateTimeSchedules: '',
        timeSchedules: '',
        loadingSchedule: true,
        geographicZone: filters.geographicZone,
        professionalsToReassign: [],
        originalProfessionalsToReassign: [],
        events: [],
        schedules: [],
        schedulesSelected: [],
        filters: {
          ...prev.filters,
        }
      }));

      if (filters.geographicZone && !filters.geographicZone.geographicZoneId) {
        PersonApiInvoker.getEmployeeGeographics(
          stepsValues.employeeId,
          data => {
            if (data.length > 0) {
              setStepsValues((prev) => ({ ...prev, geographicZone: data[0] }));
            }
          }, (error) => console.error('** error getEmployeeGeographics:', error)
        )
      }
      getSchedulesReasign(stepsValues, startReassign, endReassign);
    }
  }

  const showGraphicsInfo = (rowInfo) => { // step 0
    if (rowInfo?.original?.employeeId) {
      setStepsValues((prev) => ({
        ...prev,
        graphics: true,
        employeeId: rowInfo.original.employeeId,
      }), () => {
        setStepsValues((prev) => ({ ...prev, graphics: false }));
      });
    }
  }

  const clearInformation = () => { // All Steps
    setStepsValues({
      ...initialValues,
      practicesType: stepsValues.practicesType,
      chips: [],
      dateTimeSchedules: '',
      timeSchedules: '',
      filters: filtersChips
    });
    setCheckAllSchedules(false);
    setActiveStep(0);
    setItemsSteps(getSteps(props.location?.state?.appointment?.transferWay || props.location?.state?.transfer?.type));
    window.scrollTo(0, 0);
  }

  const removeCompletedStep = (step) => {
    setItemsSteps((prev) => {
      const dataUpdate = [...prev];
      const idx = dataUpdate.findIndex((d) => d.number === step);
      if (idx !== -1) {
        dataUpdate[idx] = {
          ...dataUpdate[idx],
          completed: false,
        };
      }
      return dataUpdate;
    });
  }

  const formatPracticesType = (practiceType) => practiceType.map(e => ({
    id: e.practiceTypeId,
    value: e.name,
  }));

  const getPracticesType = () => {
    AppointmentApiInvoker.getPracticeTypes(data => {
      if (data?.length) {
        const dataFormat = formatPracticesType(data);
        setStepsValues((prev) => ({
          ...prev,
          practicesType: dataFormat,
        }));
      }
    }, (error) => console.error('** error getPracticeTypes', error));
  }

  const renderItemsSteps = () => itemsSteps.map((step) => {
    if (step.isOpcional) {
      step.optional = <Typography className={classes.optionalCaption} variant="caption" align="center">{t('label.optional')}</Typography>;
    }
    const labelProps = {
      optional: step.optional,
    }
    const stepProps = {
      completed: step.completed,
    }
    return (
      <Step id={`step-${step.number + 1}`} key={step.number} {...stepProps}>
        <StepLabel {...labelProps}>{t(step.label)}</StepLabel>
      </Step>
    );
  });

  const getHospital = () => {
    AppointmentApiInvoker.getHospital((response) => {
      setHospitals(formatHospital(response));
    });
  };

  const formatHospital = (data) => {
    
    let result = data.map((c) => {
      return {
        id: c.hospitalId || '',
        value: c.businessName || '',
        address: c.address || '',
        latitude: c.latitude || 0,
        longitude: c.longitude || 0,
      };
    });
    return result;
  };
  const formatPartner = (data) => {
    console.log("Data",data)
    let result = data.map((c) => {
      return {
        id: c.companyIdPartnership.companyId,
        value: c.companyIdPartnership.name,
      };
    });
    return result;
  };

  const getPartner = () => {
    AppointmentApiInvoker.getCompanyPartnershipMobility((response) => {
      setPrestadores(formatPartner(response));
    });
  };
  
  const handleChangeValue = (key,value,items)=>{
    console.log(key,value, items)
    if(key === "recurrence"){
      setRecurrence(value)
    }
    setCustomerDetail((prev) => 
      ({ ...prev, 
        [key]: value
      }
      ))
  }

  const convertTo24HourFormat = (time) => {
    if (time) {
      const [timePart, period] = (time?.split(' ') ?? ['', '']);
      let [hours, minutes] = (timePart?.split(':') ?? ['00', '00']);
      hours = parseInt(hours || '0', 10);
      const lowerPeriod = (period || '').toLowerCase();
  
      if (lowerPeriod === 'am') {
        if (hours === 12) {
          hours = 0; 
        }
      } else if (lowerPeriod === 'pm') {
        if (hours !== 12) {
          hours += 12; 
        }
      }
      hours = hours.toString().padStart(2, '0');
      minutes = (minutes || '00').padStart(2, '0');
      
      return `${hours}:${minutes}`;
    }
    return '';
  };

  const formatDate = (date) => {
    let newDate = moment(date);
    if (!newDate.isValid()) {
        console.error("Fecha inválida");
        return null;
    }

    let result = newDate.format('D/MM/YYYY');
    return result;
  };

  const formatTransferTypeRecive = (type) => {
    console.log("este es el type",)
    if (type === 1) {
      return "STANDAR";
    } else if (type === 2) {
      return "RAMPA";
    } else if (type === 3) {
      return "AMBULANCIA";
    }
  };

  const formatObjectTransfer = () => {
    console.log("tipo", transfer);
    console.log("customerDetail", customerDetail);
  
    setTransfer(prevTransfer => {
      const newOrder = (prevTransfer.items && Array.isArray(prevTransfer.items) ? prevTransfer.items.length : 0) + 1;
      const newItem = {
        order: newOrder,
        recurrenceType: customerDetail.recurrence.recurrenceType,
        every: Number(customerDetail.recurrence.every),
        //endDate: customerDetail.recurrence.endDate,
        finish: customerDetail.recurrence.finish,
        finishDate: customerDetail.recurrence.finishDate,
        finishOcurrences: customerDetail.recurrence.finishOcurrences,
        monthRecurrence: customerDetail.recurrence.monthRecurrence,
        quantityIntraday: customerDetail.recurrence.quantityIntraday,
        weekRecurrence:customerDetail.recurrence.weekRecurrence,
        companyIdPartnership: {
          companyId: customerDetail.prestador || customerDetail.prestadorVuelta
        },
        endtype: "DATE",
        /*driver: {
          personId: 4866
        },*/
        processingScheduleType: "SCHEDULE",
        transferItemRequestStatus: "CREATED",
        startTime: (transfer.type === 1 || transfer.type === 3) ? convertTo24HourFormat(customerDetail?.startTimeDeparture ?? '10:00 am') : convertTo24HourFormat(customerDetail?.requestArrivalTimeReturn ?? '10:00 pm'),
        endTime: (transfer.type === 1 || transfer.type === 3) ? convertTo24HourFormat(customerDetail?.requestArrivalTimeDeparture ?? '10:00 pm') : convertTo24HourFormat(customerDetail?.withdrawalTimeReturn ?? '10:00 am'),
        startDate: customerDetail?.startDateDeparture?.split(' ')[0] ?? customerDetail?.arrivalDateReturn?.split(' ')[0],
        endDate: customerDetail?.shiftDateDeparture?.split(' ')[0] ?? customerDetail?.shiftDateReturn?.split(' ')[0],

        startDateRoundTrip:(transfer.type === 2 || transfer.type === 3) ? customerDetail?.arrivalDateReturn?.split(' ')[0] : '',
        endDateRoundTrip:(transfer.type === 2 || transfer.type === 3) ? customerDetail?.shiftDateReturn?.split(' ')[0] : '',
        startTimeRoundTrip:(transfer.type === 2 || transfer.type === 3) ? convertTo24HourFormat(customerDetail?.requestArrivalTimeReturn ?? '10:00 pm'):'',
        endTimeRoundTrip:(transfer.type === 2 || transfer.type === 3) ? convertTo24HourFormat(customerDetail?.withdrawalTimeReturn ?? '10:00 am'):'',

        transferType: formatTransferTypeRecive(customerDetail?.trasladoIda || customerDetail?.trasladoVuelta) ,
        distance:parseFloat(customerDetail?.distance?.match(/[0-9.]+/)[0]) || '',
        transferWay: transfer.type === 1 ? "ONEWAY" : transfer.type === 2 ? "RETURNTRIP" : "ROUNDTRIP",
        duration: ''/*customerDetail?.duration || ''*/,
        
        durationRoundTrip:'' /*customerDetail?.durationRoundTrip||''*/,
        distanceRoundTrip: parseFloat(customerDetail?.distanceRoundTrip?.match(/[0-9.]+/)[0]),
        ...(props.location.state.origin ? { hospitalId: { hospitalId: customerDetail?.nosocomio || customerDetail?.nosocomioVuelta } }: { hospital: { hospitalId: customerDetail?.nosocomio || customerDetail?.nosocomioVuelta } })
      };
  
      const updatedItems = prevTransfer.items && Array.isArray(prevTransfer.items)
        ? [...prevTransfer.items, newItem]
        : [newItem];
  
      const reorderedItems = updatedItems.sort((a, b) => a.order - b.order).map((item, index) => ({
        ...item,
        order: index + 1
      }));
  
      console.log("Previous transfer items:", prevTransfer.items);
      console.log("New item to add:", newItem);
      console.log("Updated items:", updatedItems);
      console.log("Reordered items:", reorderedItems);
  
      return {
        ...prevTransfer,
        items: reorderedItems,
      };
    });
  };
  const saveItemTransfer = () =>{
    console.log("este es el transfer a enviar", transfer)
    if(props.location.state.origin ){
      AppointmentApiInvoker.postEditTransfer(props.location.state.origin,transfer?.items[0], (response) => {
        setShowConfirmationAddTransfer(true)
      }, (error) => {
        
        console.error('** error', error);
      });
    }else{
      browserHistory.push({
        pathname: props.location.state.origin ? `transferappointments/${props.location.state.origin }/edit/agenda` : "/nueva-solicitud-traslado",
        state: { transferObject: transfer }
      });
    }
  }

  const successAddTransferItem = ()=>{
    setShowConfirmationAddTransfer(false)
    browserHistory.push({
      pathname: `transferappointments/${props.location.state.origin }/edit/agenda`,
      state: { mode: "edit" }
    });
  }

  const renderStepContent = (step) => {
    const { appointment } = props.location.state; 
    const { transferWay } = appointment;
    console.log("TRANSFER",transfer)
    const allSteps = [
      {
        key: 0,
        component: (
          <DialogTraslado
            handleChange={handleChange}
            handleIsValidStep={handleIsValidStep}
            isConfirmStep={isConfirmStep}
            values={{ ...stepsValues, checkAllSchedules }}
            softFilter={softFilter}
            setFilters={setFilters}
            showGraphicsInfo={showGraphicsInfo}
            clearInformation={clearInformation}
            onSelectAllSchedules={onSelectAllSchedules}
            hospitals={hospitals}
            startDate={transfer?.startDate}
            endDate={transfer?.endDate}
            prestadores={prestadores}
            formType="ida"
            transferWay={transfer.type}
            handleChangeValue={handleChangeValue}
            customerDetail={customerDetail}
            map={optionsMap}
          />
        ),
      },
      {
        key: 1,
        component: (
          <DialogTraslado
            handleChange={handleChange}
            handleIsValidStep={handleIsValidStep}
            isConfirmStep={isConfirmStep}
            values={{ ...stepsValues, checkAllSchedules }}
            softFilter={softFilter}
            setFilters={setFilters}
            showGraphicsInfo={showGraphicsInfo}
            clearInformation={clearInformation}
            onSelectAllSchedules={onSelectAllSchedules}
            hospitals={hospitals}
            prestadores={prestadores}
            formType="vuelta"
            startDate={transfer?.startDate}
            endDate={transfer?.endDate}
            transferWay={transfer.type}
            handleChangeValue={handleChangeValue}
            customerDetail={customerDetail}
            map={optionsMap}
          />
        ),
      },
      {
        key: 2,
        component: (
          <DialogTraslado
            handleChange={handleChange}
            handleIsValidStep={handleIsValidStep}
            isConfirmStep={isConfirmStep}
            values={{ ...stepsValues, checkAllSchedules }}
            softFilter={softFilter}
            setFilters={setFilters}
            showGraphicsInfo={showGraphicsInfo}
            clearInformation={clearInformation}
            onSelectAllSchedules={onSelectAllSchedules}
            formType="resumen"
            prestadores={prestadores}
            handleChangeValue={handleChangeValue}
            startDate={transfer?.startDate}
            endDate={transfer?.endDate}
            customerDetail={customerDetail}
            map={optionsMap}
            transferWay={props.location?.state?.appointment?.transferWay || transfer.type}
          />
        ),
      },
      {
        key: 3,
        component: (
          <ServicesSummary
            handleChange={handleChange}
            handleIsValidStep={handleIsValidStep}
            isConfirmStep={isConfirmStep}
            values={{ ...stepsValues, checkAllSchedules }}
            softFilter={softFilter}
            setFilters={setFilters}
            showGraphicsInfo={showGraphicsInfo}
            clearInformation={clearInformation}
            onSelectAllSchedules={onSelectAllSchedules}
            customerDetail={customerDetail}
            map={optionsMap}
            transferWay={props.location?.state?.appointment?.transferWay || transfer.type}
          />
        ),
      },
    ];
  
    let filteredSteps = allSteps;
    if (transferWay === 1 || transfer.type === 1) {
      filteredSteps = allSteps.filter(step => step.key !== 1);
    } else if (transferWay === 2 || transfer.type === 2) {
      filteredSteps = allSteps.filter(step => step.key !== 0);
    }
  
    // Ajustar el índice del paso actual para los pasos filtrados
    const adjustedStep = step < filteredSteps.length ? step : filteredSteps.length - 1;
  
    // Renderizar el componente correspondiente al paso ajustado
    return filteredSteps[adjustedStep]?.component || null;
  };

  

  useEffect(() => {
    setTransfer(props.location.state.transfer)
    getHospital();
    getPartner();
    clearInformation();
    getPracticesType();
    getPatient()
  }, []);

  const stickyStepper = useRef();

  useLayoutEffect(() => {
    const contentStepper = document.getElementById('contentStepper');
    const tabsStepper = document.getElementById('tabsStepper');
    const fixedTop = stickyStepper.current.offsetTop;

    const fixedHeader = () => {
      if (window.scrollY > 150 && window.scrollY > fixedTop) {
        contentStepper.classList.add('stepsFixed');
        tabsStepper.classList.add('fixedTop');
      } else {
        contentStepper.classList.remove('stepsFixed');
        tabsStepper.classList.remove('fixedTop');
      }
    }
    window.addEventListener('scroll', fixedHeader);
  }, [])

  return (
    <GridContainer className={classes.root}>
      <GridItem xs={12}>
        <Card>
          <CardHeader icon>
            <CardIcon color="primary">
              <Car />
            </CardIcon>
            <h4 className="card-title">Creación de la Solicitud de Traslado</h4>
          </CardHeader>
          <CardBody className={classes.contentStepper} id="contentStepper">
            <Stepper
              className={`${classes.stepper} tabsStepper`}
              activeStep={activeStep}
              alternativeLabel
              ref={stickyStepper}
              id="tabsStepper"
            >
              {renderItemsSteps()}
            </Stepper>
            <GridContainer >
              <Card>
                <CardBody>
                <GridContainer xs={12}>
                  <GridItem xs={2} sm={2} align="center" >
                    <Typography variant="subtitle2" style={{ textTransform: 'none' }}>
                      Paciente
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'none' }}>
                      {customerDetail.firstName} {customerDetail.lastName}
                    </Typography>
                  </GridItem>
                  <GridItem xs={2} sm={2} align="center">
                    <Typography variant="subtitle2" style={{ textTransform: 'none' }}>
                      Dirección
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'none' }}>
                      {customerDetail.address?.[0]?.street} {customerDetail.address?.[0]?.number}
                    </Typography>
                  </GridItem>
                  <GridItem xs={2} sm={2} align="center">
                    <Typography variant="subtitle2" style={{ textTransform: 'none' }}>
                      Localidad
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'none' }}>
                    {customerDetail.address?.[0].location.name}
                    </Typography>
                  </GridItem>
                  <GridItem xs={2} sm={2} align="center">
                    <Typography variant="subtitle2" style={{ textTransform: 'none' }}>
                      Provincia
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'none' }}>
                    {customerDetail.address?.[0].location.name}
                    </Typography>
                  </GridItem>
                  <GridItem xs={2} sm={2} align="center">
                    <Typography variant="subtitle2" style={{ textTransform: 'none' }}>
                      Fecha de inicio
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'none' }}>
                      {formatDate(props.location.state?.appointment?.provider?.dateStart ?? props.location.state?.transfer?.startDate)}
                    </Typography>
                  </GridItem>
                  <GridItem xs={2} sm={2} align="center">
                    <Typography variant="subtitle2" style={{ textTransform: 'none' }}>
                      Fecha Fin
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'none' }}>
                    {formatDate(props.location.state?.appointment?.provider?.dateEnd ?? props.location.state?.transfer?.endDate)}
                    </Typography>
                  </GridItem>
                </GridContainer>
                </CardBody>
              </Card>
            </GridContainer>
            {renderStepContent(activeStep)}

            <NotificationState notification={notification} />
            
          </CardBody>
        </Card>
        <ButtonsSteps
          buttonType="detail-service"
          handleBack={handleBack}
          handleChange={handleChange}
          handleNext={handleNext}
          handleSkipStep={handleSkipStep}
          handleGoBack={goBack}
          handleConfirm={saveItemTransfer}
          transfer={formatObjectTransfer}
          customerDetail={customerDetail}
          transferWay={props.location?.state?.appointment?.transferWay || props.location?.state?.transfer.type}
          values={{...stepsValues, activeStep, refStepsValues}}
          steps={itemsSteps}
          showSnackbarNotification={showSnackbarNotification}
          clearInformation={clearInformation}
          removeCompletedStep={removeCompletedStep}
          recurrence={recurrence}
        />
        {showConfirmationAddTransfer &&
          <CustomSweetAlert
            type="success"
            onConfirm={successAddTransferItem}
            confirmBtnCssClass="primary"
            title={t('appointment.created.add.confirmation.title')}
            confirmBtnText={t('appointment.created.cancel.confirmation.OK')}
            showCancel={false}
            message={<p>{t('appointment.created.edit.confirmation.message')}</p>}
          />
        }
      </GridItem>
    </GridContainer>
  );
}

ReassignAppointmentsStepper.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withTranslation()(ReassignAppointmentsStepper);
